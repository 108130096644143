import * as React from 'react'
import Layout from '../components/layout'

const Article = ({ children }) => {
  return (
    <Layout>
      <article className='container-fluid'>
        <div className='row justify-content-center py-5'>
          <div className='col-md-8 col-lg-6'>{children}</div>
        </div>
      </article>
    </Layout>
  )
}

export default Article

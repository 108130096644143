import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'

const AboutUs = () => {
  return (
    <>
      <Seo
        pageDescription={`The Will Centre works up and down the country talking to individuals
        providing them with a sense of security and breathes everlasting life
        into the hearts of people, families, loved ones for generations to
        come.`}
        pageTitle={'About Us'}
      />
      <Article>
        <h1>About Us</h1>
        <p>
          The Will Centre works up and down the country talking to individuals
          providing them with a sense of security and breathes everlasting life
          into the hearts of people, families, loved ones for generations to
          come.
        </p>

        <p>
          Our focus is to educate as many people as possible on the importance
          of afterlife planning and how they can achieve this by being given a
          little direction from our expertise.
        </p>
      </Article>
    </>
  )
}

export default AboutUs
